<template>
  <div class="home-wrap">
    <div class="hero home-image">
      <img class="hero-logo" src="@/assets/bravado_logo_text.png" alt="bravado_logo">
      <h2>{ Web Development }<br> Sorted</h2>
    </div>
    <Footer class="fixed-footer"/>
  </div>
</template>




<script>
import Footer from './Footer'

export default {

  name:  'Home',

  components: {
    Footer
  },

  data() {
    return {
      background: require('@/assets/home_background.jpg')
    }
  },

}
</script>





<style>

.home-wrap {
 
}

.hero {
  height: 100vh;
  max-width: 100vw;


  
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;


  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.hero.home-image {
  background-image: url('../assets/home_background.jpg');
}



.hero-logo {
  max-width: 350px;
  margin-top: -4em;
  margin-bottom: 1em;
}


.hero h2 {
  text-align: center;
  padding: 0.5em 1em;
  border: 1px solid rgb(220,0,0);
  background: rgba(255,255,255,0.4);
  border-radius: 5px;
}




@media (max-width: 700px) {
  .hero {
    background-attachment: scroll;
  }

  .hero-logo {
    max-width: 70%;
  } 
}

@media (orientation: landscape) and (max-width: 700px) {
    
    .hero-logo {
      max-height: 40%;
      margin-top: 1em;
    }
    
}


</style>