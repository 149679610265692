<template>
    <div class="about-wrap">

        <div class="hero about-image">
            <h2>About Bravado</h2>
        

            <div id="About">
                <p>Bravado is a brand-based web solutions agency. We take care of everything your brand and it's online presence needs: From registering your domain and setting up hosting, all the way to your online advertising through Google Adwords, search engine optimization and of course, building whatever awesome idea you have into an online solution that is ready to be discovered by your clients.</p>
                <br>
                <p>Established in 2018, Bravado aims to become one of the top freelance web development agencies in South Africa.</p>
                <br>


            </div>
        </div>
        <Footer class="fixed-footer"/>
    </div>
</template>

<script>
import Footer from './Footer'

export default {

    name: 'About',

    components: {
        Footer
    },

    methods: {

        scrollToElement: function(id) {
            var element = document.getElementById(id);
            window.scrollTo(0,element.offsetTop - 100);
            
        }

    }

}
</script>

<style>

.about-wrap {

}

.hero.about-image {
    background-image: url('../assets/about_background.jpg');
}

.hero.about-image h2 {
    margin-bottom: 2em;
    font-size: 18px;
}


#About {
    padding: 0 6em;
    text-align: center;
}

.quote {
    padding: 0 1em;
    font-size: 18px;
    text-align: center;
}

@media (max-width: 700px) {
    #About {
        padding: 0 2em;
    }
}

@media (orientation: landscape) and (max-width: 700px) {
    
    .hero.about-image h2 {
        margin-bottom: 1em;
        margin-top: 1em;
        font-size: 16px;
    }
}

</style>