<template>
    <div class="service-selector-wrap" :class="{ show : show }">


        <div class="service-menu-toggle-wrap" @click="toggleServiceMenu">
            <span class="material-icons">menu_open</span>
            <span>Quick Select</span> 
        </div>

        <div id="ServicesMenu" class="serviceMenu" :class="{ show : showMenu }">
            <h4>Services</h4>
            <ul>
                <li @click="scrollToService('HD')">Hosting & Domains</li>
                <li @click="scrollToService('CMS')">Content Management Systems</li>
                <li @click="scrollToService('CW')">Custom Websites</li>
                <li @click="scrollToService('EC')">E-commerce</li>
                <li @click="scrollToService('PWA')">Progressive Web Applications</li>
                <li @click="scrollToService('SEO')">SEO</li>
                <li @click="scrollToService('GD')">Graphic Design</li>
                <li @click="scrollToService('CC')">Content Creation</li>
            </ul>
        </div>
    </div>
</template>

<script>


export default {

    name: 'ServiceQuickSelector',

    data() {
        return {
            show: false,
            showMenu: false
        }
    },


    created() {

        if(window.pageYOffset > 500) {
            this.show =  true;
        } else {
            this.show = false;
        }

        window.addEventListener('scroll', this.toggleQuickSelector);
        
    },


    methods: {


        toggleQuickSelector: function() {

            if(window.pageYOffset > 500) {
                this.show =  true;
            } else {
                this.show = false;
            }
        },


        toggleServiceMenu: function() {
            this.showMenu = !this.showMenu;

            setTimeout(() => {
                if(this.showMenu) {
                    window.addEventListener('click', this.listenForOutsideClick);
                } else {
                    window.removeEventListener('click', this.listenForOutsideClick);
                }
            },100)
            
            
        },


        scrollToService: function(service) {
            var element = document.getElementById(service);
            window.scrollTo(0, element.offsetTop - 100);

            this.showMenu = false;
        },


        listenForOutsideClick: function(e) {

            if(this.showMenu && e.target.id !== 'ServicesMenu') {

                this.showMenu = false;

            }

            window.removeEventListener('click', this.listenForOutsideClick);
        }
    }

}
</script>

<style>

.service-selector-wrap {
    position: fixed;
    bottom: 18px;
    left: -500px;

    transition: left 500ms ease;
}

.service-selector-wrap.show {
    left: 0;
    padding: 0.3em 1em;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    transition: left 500ms ease;
    background: rgba(40,40,40,0.8);
}



.service-menu-toggle-wrap {
    font-size: 14px;

    display: flex;
    align-items: center;

    cursor: pointer;

    z-index: 1000;

    color: white;

    
}

.service-menu-toggle-wrap .material-icons {
    color: rgb(200,0,0);
}



.serviceMenu {
    z-index: 999;
    position: fixed;
    left: -500px;
    bottom: 120px;

    background: rgba(250,250,250,0.9);

    padding: 1em;

    opacity: 0;

    transition: left 700ms ease, opacity 500ms ease;
}

.serviceMenu.show {
    bottom: 120px;
    left: 0;

    opacity: 1;

    transition: left 800ms ease, opacity 600ms ease;
    
}


.serviceMenu h4 {
    margin-bottom: 0.5em;
    color: rgb(200,0,0);
}



.serviceMenu ul {
    list-style-type: none;
}

.serviceMenu ul li {
    cursor: pointer;
    margin-bottom: 0.5em;
}

</style>