<template>
    <div class="menu">



        <!--<div class="menu-spacer" v-if="!home"></div>-->



        <div class="menu-wrap" :class="{ 'background-on': background || showMenu, mobile : mobile }">

            <div class="menu-toggle-wrap" @click="toggleMobileMenu" v-if="mobile">
                <span class="menu-toggle" :class="{ 'background-on': background || showMenu }"></span>
            </div>

            <router-link to="/"><img v-if="mobile" class="mobile-nav-logo" :class="{ show : background || showMenu }" src="@/assets/bravado_mobile_nav_logo.png" alt="bravado_logo_mobile"></router-link>

            <nav :class="{ mobile : mobile, show : showMenu}">
                <!--<img src="@/assets/bravado_logo.png" alt="bravado_logo" class="menu-logo" :class="{ 'background-on': background }" @click="$router.push('/')"/>-->
                <ul>
                    <li @click="toggleMobileMenu"><router-link to="/">Home</router-link></li>
                    <li @click="toggleMobileMenu"><router-link to="/services">Services</router-link></li>
                    <li @click="toggleMobileMenu"><router-link to="/projects">Projects</router-link></li>
                    <li @click="toggleMobileMenu"><router-link to="/about">About</router-link></li>
                    <li @click="toggleMobileMenu"><router-link to="/contact">Get in touch</router-link></li>
                </ul>
            </nav>
        </div>



    </div>
</template>

<script>
export default {

    name:'Menu',

    data() {
        return {
            home: true,
            background: false,
            mobile: false,
            showMenu: false
        }
    },

    watch: {
        $route () {
            if(this.$router.history.current.path === '/') {
            
                this.home = true

            } else {
                
                this.home = false

            }
        }
    },

    created() {

        if(window.innerWidth < 700) {
            this.mobile = true;
        }

        if(this.$router.history.current.path === '/') {
            this.home = true;
        } else {
            this.home = false;
        }

        window.addEventListener('scroll', this.scrollMenu);
        window.addEventListener('resize', this.resizeScreen);

    },


    methods: {

        toggleMobileMenu: function() {

            if(this.mobile) {

                this.showMenu = !this.showMenu;

                setTimeout(() => {
                    if(this.showMenu) {
                        window.addEventListener('click', this.listenForOutsideClick);
                    } else {
                        window.removeEventListener('click', this.listenForOutsideClick);
                    }
                },100)
                
            }
            
        },

        resizeScreen: function() {
            if(window.innerWidth > 700) {
                this.mobile = false
            } else {
                this.mobile = true
            }
        },



        scrollMenu: function() {
            if(window.pageYOffset > 120) {
                this.background = true;
            } else {
                this.background = false;
            }
        },


        listenForOutsideClick: function(e) {

            if(this.showMenu && e.target.id !== 'ServicesMenu') {

                this.showMenu = false;

            }

            window.removeEventListener('click', this.listenForOutsideClick);
        }
    }

}
</script>

<style>

.menu {
    z-index: 1050;
}

.menu-wrap {
    width: 100vw;
    background: rgba(255, 255, 255, 0);
    
    position: fixed;
    top: 0;
    left: 0;

    z-index: 999;

    transition: background 700ms ease, box-shadow 700ms ease;

    
}

.menu-wrap a {
    color: rgb(20,20,20);
    transition: color 500ms ease;
}

.menu-wrap.background-on {
    background: rgba(0, 0, 5, 0.8);
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2);
    transition: background 800ms ease, box-shadow 800ms ease;
}

.menu-wrap.background-on a {
    color: rgb(255,255,255);
    transition: color 500ms ease;
}


.menu-spacer {
    height: 80px;
    background: transparent;
}





nav {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    position: relative;
}

.menu-logo {
    width: 60px;
    position: absolute;
    top: 0.8em;
    left: 2em;
    opacity: 0;
    transition: opacity 500ms ease;
    cursor: pointer;
}

@keyframes fade-logo {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.menu-logo.background-on {
    opacity: 1;
    animation: fade-logo 800ms ease;
}



nav ul {
    width: 80%;
    display: flex;
    justify-content: space-between;

    list-style-type: none;
}

nav ul li {

}

nav ul li a {
    text-decoration: none;
    font-weight: 700;
}



/* Mobile Menu */

.menu-wrap.mobile {
    height: 60px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile-nav-logo {
    height: 50px;
    margin-left: -1em;
    opacity: 0;
    transition: opacity 500ms ease;
}

.mobile-nav-logo.show {
    opacity: 1;
    transition: opacity 500ms ease;
}


nav.mobile {
    height: max-content;
    width: 50vw;

    position: absolute;
    top: 60px;
    right: -100vw;

    background: rgba(0, 0, 5, 0.8);
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2);
    z-index: 950;

    padding: 1em 0;
    transition: right 500ms ease;
}

nav.mobile.show {
    right: 0;
    transition: right 500ms ease;
}




nav.mobile ul {
    flex-direction: column;
}

nav.mobile ul li {
    margin-bottom: 1em;
    
}

nav.mobile ul li:last-child {
    margin-bottom: 0;
}

nav.mobile ul li a {
    color: white;
}



/* Mobile Menu Toggle Button */

.menu-toggle-wrap {

    position: fixed;
    top: 1px;
    right: 15px;
    
    padding: 28px 10px;
    display: flex;
    justify-content: center;

    z-index: 1000;
}


.menu-toggle, .menu-toggle::before, .menu-toggle::after {
    

    content: '';
    width: 7px;
    height: 7px;
    background: rgb(60,60,60);
    border-radius: 50%;

    transition: background 800ms ease;

    position: absolute;
    top: 26px;
    
    
}

.menu-toggle::before {
    top: -14px;
}

.menu-toggle::after {
    top: 14px;
}

.menu-toggle.background-on, .menu-toggle.background-on::before, .menu-toggle.background-on::after {
    background: white;
    transition: background 800ms ease;
}

</style>