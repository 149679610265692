<template>
    <div class="contact-wrap">

        <div class="hero contact-image">
            <h2>Get in touch</h2>
            <span class="material-icons scroll-down" @click="scrollToElement('ContactForm')">arrow_circle_down</span>
        </div>


        <div id="ContactForm">

            <h2>Please fill in the form below</h2>

            <hr class="heading-ruler contact">
            
            <h4 class="input-heading" :class="{ error : error.name }">Name</h4>
            <input id="NameInput" name="name" class="input" type="text" v-model="contact.name" :class="{ error : error.name }" @focus="error.name = false, contact.name = ''" />

            <h4 class="input-heading">Last name</h4>
            <input name="last-name" class="input" type="text" v-model="contact.lastname" />

            <h4 class="input-heading" :class="{ error : error.email }">Email</h4>
            <input name="email" class="input" type="email" v-model="contact.email" :class="{ error : error.email }" @focus="error.email = false, contact.email = ''" />

            <h4 class="input-heading">Phone</h4>
            <input name="tel" class="input" type="number" min="10" max="10" v-model="contact.phone" />

            <h4 class="input-heading">Company</h4>
            <input name="orginization" class="input" type="text" v-model="contact.company" />

            <h4 class="input-heading" :class="{ error : error.service }">How can we help?</h4>
            <p v-if="error.service" style="margin-bottom: 1em;">Please select a service or describe what you need by selecting "Other"</p>

            <select autocomplete="off" class="input-selection" v-model="contact.service" @click="error.service = false">
                <option value="" disabled>Please select...</option>
                <option value="Domain Registration">Domain Registration</option>
                <option value="Hosting">Hosting</option>
                <option value="CMS">Content Management Systems</option>
                <option value="Custom Website">Custom Website</option>
                <option value="E-commerce Website">E-commerce Website</option>
                <option value="PWA">Progressive Web Application</option>
                <option value="SEO">Search Engine Optimization</option>
                <option value="Graphic Design">Graphic Design</option>
                <option value="Content Creation">Content Creation</option>
                <option value="Other">Other</option>
            </select>

            <div v-if="contact.service === 'Other'">
                <h4 class="input-heading" :class="{ error : error.other }">Please describe the service you require</h4>
                <input autocomplete="off" class="input" type="text" v-model="contact.other" :class="{ error : error.other }" @focus="error.other = false, contact.other = ''"/>
            </div>

            <h4 class="input-heading">Your Message</h4>
            <textarea class="message-box" v-model="contact.msg"></textarea>

            <vue-recaptcha sitekey="6LckD1waAAAAALUtuJYSAR8c05SijZEo9MUIo5ml">
                <button class="btn" @click="submitContactForm">Submit</button>
            </vue-recaptcha>

        </div>

        <Modal :modal="modal"/>
        <Footer />
    </div>
</template>




<script>
import axios from 'axios'
import VueRecaptcha from 'vue-recaptcha';
import Footer from './Footer'

import Modal from './Modal'



export default {
    name: 'Contact',

    components: {
        VueRecaptcha,
        Modal,
        Footer
    },

    data() {
        return {

            modal: {},


            contact: {
                name: '',
                lastname: '',
                email: '',
                phone: '',
                company: '',
                service: '',
                msg: '',
                other: ''
            },

            error: {
                name: false,
                email: false,
                service: false,
                other: false
            },

            regexEmail: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/

        }
    },



    methods: {


        testModal: function(type) {
            this.modal = {
                show: true,
                title: 'some title',
                body: '',
                error: type
            }
        },  


        scrollToElement: function(id) {
            var element = document.getElementById(id);
            window.scrollTo(0,element.offsetTop - 100);
            
        },



        canSubmitContact: function() {

            var flag = true;


            if(!this.regexEmail.test(this.contact.email)) {
                this.error.email = true;
                this.contact.email = 'Please enter a valid email address';
                flag = false;
            }

            if(this.contact.name === '') {
                this.error.name = true;
                flag = false;
            }

            if(this.contact.service === '') {
                this.error.service = true;
                flag = false;
            }

            if(this.contact.service === 'Other' && this.contact.other === '') {
                this.error.other = true;
                flag = false;
            }

            return flag;

        },


        submitContactForm: function() {

            
            if(!this.canSubmitContact()) {
                this.modal = {
                    show: true,
                    title: 'Please fill in all required fields.',
                    body: '',
                    error: true
                }
                window.scrollTo(0,document.getElementById('NameInput').offsetTop - 150);
                return
            }
            

            axios.post('contact.php', this.contact, {
                headers: {'Content-type' : 'application/x-www-form-urlencoded'}
            })
                .then(resp => {


                    if(resp.data.status === 'success') {
                        this.modal = {
                            show: true,
                            title: 'Your message has been sent!',
                            body: 'Thank you for your enquiry, we will get back to you very soon.',
                            error: false
                        }

                    } 
                    
                    else {
                        this.modal = {
                            show: true,
                            title: 'Oh no, your message could not be sent',
                            body: 'Please email us <a href="mailto:info@bravado.co.za">here</a>',
                            error: true
                        }
                    }


                })

        }
        

    }
}
</script>

<style>

/* Recapctha always on top */
.grecaptcha-badge {
  z-index: 1000;
}


.hero.contact-image {
    background-image: url('../assets/contact_background.jpg');
    margin-bottom: 5em;
}



#ContactForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5em;
    text-align: center;
}

.heading-ruler.contact {
    width: 30vw;
    margin-top: 1em;
}



.input-heading {
    margin-bottom: 0.5em;
}

.input-heading.error {
    color: rgb(220,0,0);
}



.input {
    border: none;
    border-bottom: 1px solid;
    width: 40vw;
    margin-bottom: 4em;
    text-align: center;
}

.input.error {
    border-bottom: 1px solid rgb(220,0,0);
}

.input-selection {
    margin-bottom: 4em;
}


.message-box {
    width: 60vw;
    height: 150px;
    margin-bottom: 3em;
    border-radius: 5px;
}



@media (max-width: 700px) {

    .heading-ruler.contact {
        width: 45vw;
        margin-top: 1em;
    }


    .input {
        width: 60vw;
    }

    .message-box {
        width: 75vw;
    }
}

</style>