import Home from './components/Home'
import Services from './components/Services'
import Projects from './components/Projects'
import About from './components/About'
import Contact from './components/Contact'

export default [
    {
        path: '/',
        component: Home,
        meta: {
            title: 'Bravado - Home'
        }
    },

    {
        path: '/services',
        component: Services,
        meta: {
            title: 'Bravado - Services'
        }
    },

    {
        path: '/projects',
        component: Projects,
        meta: {
            title: 'Bravado - Projects'
        }
    },

    {
        path: '/about',
        component: About,
        meta: {
            title: 'Bravado - About Us'
        }
    },

    {
        path: '/contact',
        component: Contact,
        meta: {
            title: 'Bravado - Contact Us'
        }
    }
]