<template>
    <div class="footer-wrap">Copyright &#169; {{ year }} - Bravado</div>
</template>

<script>

export default {

    name: 'Footer',

    data() {
        return {
            year: new Date().getFullYear()
        }
    }

}
</script>

<style>

.footer-wrap {
    
    width: 100%;

    display: flex;
    justify-content: center;

    font-size: 12px;
    margin-bottom: 5px;
}

</style>