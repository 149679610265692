import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import routes from './routes.js'
import axios from 'axios'


Vue.use(VueRouter, axios);


const router = new VueRouter({
  mode: 'history',
  routes: routes,
  scrollBehavior () {
    return { x: 0, y: 0 };
  }
})



const DEFAULT_TITLE = 'Bravado'

// Apply Document Title from routes meta   OR   default to "Bravado"
router.afterEach((to) => {
  Vue.nextTick(() => {
      document.title = to.meta.title || DEFAULT_TITLE;
  });
  

})

Vue.config.productionTip = false


if(process.env.NODE_ENV === 'production') {
  //console.log('prod');
  axios.defaults.baseURL = 'https://bravado.co.za/api/';
} else if(process.env.NODE_ENV === 'development'){
  //console.log('dev');
  axios.defaults.baseURL = 'http://localhost/bravado/api/';
}





new Vue({
  render: h => h(App),
  router,
}).$mount('#app')