<template>

    <div class="projects-wrap">

        <div class="hero projects-image">
            <h2>Projects</h2>
            <span class="material-icons scroll-down" @click="scrollToElement('Projects')">arrow_circle_down</span>
        </div>

        <div id="Projects">

            <h2 class="projects-heading">Our Latest Projects</h2>

            <hr class="heading-ruler">




            <div class="project">

                <img class="project-logo" src="@/assets/Tabula_logo_dark500x500.png" alt="bravado-project-tabula-rasa-marketing-agency">
                
                <div class="project-window">
        
                    <img class="project-desktop" src="@/assets/tabula_desktop_screenshot.jpg" alt="">
                    <img class="project-mobile" src="@/assets/tabula_mobile_screenshot.png" alt="">

                </div>
            </div>


            



            <div class="project">

                <img class="project-logo" src="@/assets/shoplist_logo.png" alt="bravado-project-shoplist-pwa">
                
                <div class="project-window mobile">
        
                    <img class="project-tablet" src="@/assets/shoplist_tablet_screenshot.png" alt="">
                    <img class="project-mobile" src="@/assets/shoplist_mobile_screenshot.png" alt="">

                </div>
                <div class="shoplist-blurb">
                    <h4>Why not create a Shoplist account? It's completely free!</h4>
                    <p style="font-size: 12px">(Please note that Shoplist runs in portrait orientation on mobile devices)</p>
                    <a class="register-shoplist-link" href="https://shoplist.bravado.co.za/register" target="_blank">Click here to register</a>
                </div>
            </div>




            <div class="project">

                <img class="project-logo" src="@/assets/spyglass_logo.png" alt="bravado-project-spyglass-commercial-advisors">
                
                <div class="project-window">
        
                    <img class="project-desktop" src="@/assets/spyglass_desktop_screenshot.jpg" alt="">
                    <img class="project-mobile" src="@/assets/spyglass_mobile_screenshot.png" alt="">

                </div>
            </div>





        </div>

        <Footer />
      
    </div>

</template>

<script>
import Footer from './Footer'

export default {

  name: "Projects",

  components: {
      Footer
  },

    methods: {

        scrollToElement: function(id) {
            var element = document.getElementById(id);
            window.scrollTo(0,element.offsetTop - 100);
            
        }

    }

}
</script>

<style>

.hero.projects-image {
    background-image: url('../assets/projects_background.jpg');
    margin-bottom: 5em;
}


#Projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}


.projects-heading {
    margin-bottom: 0.5em;
}

.heading-ruler {
    width: 40vw;
    margin-bottom: 4em;
    border-color: rgb(220,0,0);
}



.project {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-bottom: 8em;

}

.project-logo {
    max-width: 150px;
    margin-bottom: 2em;
}

.project-window {
    width: max-content;
    height: 40vw;

    position: relative;

}

.project-window.mobile {
    width: 65vw;
    height: 50vw;

    display: flex;
    justify-content: center;
}

.project-desktop {
    border-radius: 10px;
    max-width: 100%;
    max-height: 100%;
    box-shadow: -3px 5px 12px 0 rgba(0,0,0,0.4);
}

.project-tablet {
    border-radius: 10px;
    max-width: 100%;
    max-height: 100%;
    
}

.project-mobile {
    max-width: 100%;
    max-height: 100%;


    position: absolute;
    right: 2em;
    bottom: -5em;
}

.project-window.mobile .project-mobile {
    max-height: 80%;
}


.shoplist-blurb {
    text-align: center;
    z-index: 800;
    background: rgba(0,150,240,0.2);
    padding: 1em;
    border-radius: 10px;
    margin: 0 3em;
    margin-top: 5em;
     
}

.register-shoplist-link {
    color: rgb(0,150,240);
    text-decoration: none;
}





@media (max-width: 700px) {
    .hero.projects-image {
        background-position-x: 30%;
    }

    .project-mobile {
        bottom: -2em;
        right: 1em;
    }

    .project-window.mobile {
        width: 80vw;
        height: 85vw;
    }
}

</style>