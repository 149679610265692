<template>
  <div id="app">
    <Menu />
    <router-view class="page-fades"></router-view>
  </div>
</template>




<script>
import Menu from './components/Menu'


export default {
  
  name: 'App',

  components: {
    Menu
  },

}
</script>




<style>

/* Page transitions */

@keyframes fade-pages {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.page-fades {
  animation: fade-pages 800ms ease;
}

/* End page transitions */



/* Global Resets */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Nunito Sans', sans-serif;
  scroll-behavior: smooth;
  outline: none;
}

/* Global button styling */
.btn {
  padding: 0.5em 0.8em;
  background: white;
  color: rgb(220,0,0);
  border: 1px solid rgb(220,0,0);
  border-radius: 3px;
  font-weight: 700;
  box-shadow: -2px 2px 4px 0 rgba(0,0,0,0.3);
}


#app {
  /*margin-bottom: 1200px;*/
}

.fixed-footer {
  position: fixed;
  bottom: 0;
}


.small-text {
  font-size: 12px;
}

</style>
