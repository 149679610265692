<template>

    <div class="services-wrap">

        <div class="hero services-image">
            <h2>Services</h2>
            <span class="material-icons scroll-down" @click="scrollToElement('HD')">arrow_circle_down</span>
        </div>


        <div class="services-flexbox">
            <div class="services">


                <div id="HD" class="service">
                    <h4 class="service-title">Hosting & Domain Registrations</h4>
                    <img class="service-icon" src="@/assets/hosting_domain_registration_icon.png" alt="hosting-and-domain-registration">
                    <h5 class="service-price">Priced from: R149</h5>
                    <p class="service-text">Your hosting provider is a critical choice for any web-based service, affecting not only your visitor's experience but also search engine indexing. That's why we use Xneelo (formerly Hetzner). They have a proven track record and specialise in one thing only, hosting. Although there are cheaper options available in SA, we value their quality service, speed and maximum uptime over price everytime.</p>
                    <button class="btn" @click="getQuote('Hosting + Domain')">Request a Quote</button>
                </div>


                <div id="CMS" class="service">
                    <h4 class="service-title">Content Management Systems (CMS)</h4>
                    <img class="service-icon" src="@/assets/cms_icon.png" alt="cms-wesbites">
                    <h5 class="service-price">Priced from: R1999</h5>
                    <p class="service-text">As the name suggests, CMS allows you to manage your own content. Whether it's a blog, service listings or products, we build it and you can add or remove content as needed.</p>
                    <button class="btn" @click="getQuote('CMS')">Request a Quote</button>
                </div>


                <div id="CW" class="service">
                    <h4 class="service-title">Custom Websites</h4>
                    <img class="service-icon" src="@/assets/custom_websites_icon.png" alt="custom-websites">
                    <h5 class="service-price">Priced from: R2499</h5>
                    <p class="service-text">We build your website using the latest html, css and javascript, completely customized to your needs and design requirements.</p>
                    <button class="btn" @click="getQuote('Custom Website')">Request a Quote</button>
                </div>


                <div id="EC" class="service">
                    <h4 class="service-title">E-commerce Websites</h4>
                    <img class="service-icon" src="@/assets/e-commerce_icon.png" alt="e-commerce-websites">
                    <h5 class="service-price">Priced from: R3999</h5>
                    <p class="service-text">When you need to sell anything online. This can be built using a CMS or for a customized approach we will build your shop/website with a backend tailored to your needs.</p>
                    <button class="btn" @click="getQuote('E-commerce')">Request a Quote</button>
                </div>


                <div id="PWA" class="service">
                    <h4 class="service-title">Progressive Web Applications (PWA's)</h4>
                    <img class="service-icon" src="@/assets/pwa_icon.png" alt="progressive-web-applications">
                    <h5 class="service-price">Priced from: Depends on your needs</h5>
                    <p class="service-text">PWA's are fast becoming the popular choice in app development. They can be installed and run on mobile devices just like native Android/IOS applications. You can even deliver your content to users when they are offline. Supported by the most popular browsers in the world - Chrome, Safari, FireFox Mobile, Opera and Edge (as well as most Android & IOS native browsers) - PWA's are an easy and versatile solution for going mobile.</p>
                    <button class="btn" @click="getQuote('PWA')">Request a Quote</button>
                </div>            


                <div id="SEO" class="service">
                    <h4 class="service-title">Search Engine Optimization (SEO)</h4>
                    <img class="service-icon" src="@/assets/seo_icon.png" alt="search-engine-optimization">
                    <h5 class="service-price">Priced from: R499</h5>
                    <p class="service-text">Search engines have a come a long way from the days of simply entering keywords into your website. We'll make sure your website utilizes the best practices for SEO available today.</p>
                    <button class="btn" @click="getQuote('SEO')">Request a Quote</button>
                </div>


                <div id="GD" class="service">
                    <h4 class="service-title">Graphic Design</h4>
                    <img class="service-icon" src="@/assets/graphic_design_icon.png" alt="graphic-design">
                    <h5 class="service-price">Priced at: R499 p/hour</h5>
                    <p class="service-text">From logo design to full brand identities and everything in between, we are here for all your graphic design needs.</p>
                    <button class="btn" @click="getQuote('Graphic Design')">Request a Quote</button>
                </div>


                <div id="CC" class="service">
                    <h4 class="service-title">Content Creation</h4>
                    <img class="service-icon" src="@/assets/content_creation_icon.png" alt="content-creation">
                    <h5 class="service-price">Priced from: R799</h5>
                    <p class="service-text">Sometimes creating the right content for your website can be a daunting task. We can help! Using best practices, we can write SEO right into your content and ensure a great user experience as well as search engine visibilty.</p>
                    <button class="btn" @click="getQuote('Content Creation')">Request a Quote</button>
                </div>

            </div>
        </div>


        <div id="GetQuote">


            <h2 class="quote-heading1">Get a Quote</h2>
            <h3 class="quote-heading2">Please fill in the details below</h3>

            <hr class="quote-heading-hr">


            <h4 class="input-heading">Quote request for:</h4>
            <input type="text" disabled v-model="quote.service" class="selected-service">





            <div class="service-options" v-if="quote.service === 'Hosting + Domain'">

                <h4>Select the service type</h4>
                <select class="service-option-selectors" v-model="quote.contact.options.selection">
                    <option value="Hosting + Domain">Hosting + Domain Registration</option>
                    <option value="HostingOnly">Hosting Only</option>
                    <option value="DomainOnly">Domain Registration Only</option>
                </select>

                <div v-if="quote.contact.options.selection === 'Hosting + Domain' || quote.contact.options.selection === 'DomainOnly'">
                    <h4>Top 5 domain names you would like?</h4>
                    <p class="small-text">(ie www.yourdomain.com)</p>
                    <p class="small-text">(We will only check availibility on those names)</p>
                    <textarea class="text-box" v-model="quote.contact.options.domains"></textarea>
                </div>

                <div v-if="quote.contact.options.selection === 'HostingOnly'">
                    <h4>What is your current domain name? </h4>
                    <p class="small-text">(ie www.yourdomain.com)</p>
                    <input class="input" v-model="quote.contact.options.domain" />
                </div>

                
            </div>




            <div class="service-options" v-if="quote.service === 'CMS'">
                <p class="small-text">We find that having a quick discovery meeting aids in giving you more accuarte pricing for this service.</p>
                <p class="small-text meeting-required">Please submit the form and we will arrange a meeting shortly.</p>
                <h4>You can add some more detail below</h4>
                <p class="small-text">(Any specific details like the primary use of your CMS or a theme that you would like to use?)</p>
                <textarea class="text-box" v-model="quote.contact.options.info"></textarea>
            </div>





            <div class="service-options" v-if="quote.service === 'Custom Website'">
                <p class="small-text">We will contact you to discuss your website requirements.</p>
                <h4>What will the primary use of your website be?</h4>
                <input type="text" class="input" v-model="quote.contact.options.info" />
            </div>





            <div class="service-options" v-if="quote.service === 'E-commerce'">
                <p class="small-text">We will contact you shortly to discuss the details of your e-commerce website/application.</p>
                <h4>What is the main product/service you want to provide?</h4>
                <input type="text" class="input" v-model="quote.contact.options.info" />
            </div>





            <div class="service-options" v-if="quote.service === 'PWA'">
                <p class="small-text meeting-required">We will contact you to discuss your specific needs.</p>
                <h4>App description:</h4>
                <p class="small-text">In short, describe what you need your application to do...</p>
                <textarea class="text-box" v-model="quote.contact.options.info"></textarea>
            </div>




            <div class="service-options" v-if="quote.service === 'SEO'">
                <h4>Do you already have a website?</h4>
                <select style="margin-bottom: 1em;" v-model="quote.contact.options.hasWebsite">
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>
                <div v-if="quote.contact.options.hasWebsite === 'Yes'">
                    <h4>What is your website address?</h4>
                    <p class="small-text">(ie www.yourwebsite.com)</p>
                    <input class="input" v-model="quote.contact.options.domain" />
                </div>
            </div>





            <div class="service-options" v-if="quote.service === 'Graphic Design'">
                <h4>Please describe the design services you require</h4>
                <textarea class="text-box" v-model="quote.contact.options.info"></textarea>
            </div>





            <div class="service-options" v-if="quote.service === 'Content Creation'">
                <p class="small-text">We will get in touch to discuss your content creation needs.</p>
                <h4>Would you like to give us some more detail?</h4>
                <textarea class="text-box" v-model="quote.contact.options.info"></textarea>
            </div>



            <h4 class="input-heading" :class="{ error : error.name }">Name</h4>
            <input class="input" type="text" name="name" v-model="quote.contact.name" :class="{ error : error.name }" @focus="quote.contact.name = '', error.name = false" />

            <h4 class="input-heading">Last name</h4>
            <input class="input" type="text" name="last-name" v-model="quote.contact.lastname" />

            <h4 class="input-heading" :class="{ error : error.email }">Email</h4>
            <input class="input" type="text" name="email" v-model="quote.contact.email" :class="{ error : error.email }" @focus="quote.contact.email = '', error.email = false" />

            <h4 class="input-heading">Phone</h4>
            <input class="input" type="text" name="tel" v-model="quote.contact.phone" />

            <h4 class="input-heading">Company</h4>
            <input class="input" type="text" name="orginization" v-model="quote.contact.company" />

            <div style="text-align: center">
                <h4 class="input-heading">Your Message</h4>
                <p class="small-text">(Optional)</p>
                <textarea class="text-box" v-model="quote.contact.msg"></textarea>
            </div>


            <vue-recaptcha sitekey="6LckD1waAAAAALUtuJYSAR8c05SijZEo9MUIo5ml">
                <button class="btn quote-btn" @click="submitQuote">Get Quote!</button>
            </vue-recaptcha>

        </div>

        <ServiceQuickSelector />

        <Modal :modal="modal"/>
        <Footer />
    </div>

</template>



<script>

import VueRecaptcha from 'vue-recaptcha';
import axios from 'axios'

import Modal from './Modal'
import Footer from './Footer'
import ServiceQuickSelector from './ServiceSelector'

export default {

    name: "Services",

    data() {
        return {

            modal: {},

            quote: {
                service: '',
                contact: {
                    name: '',
                    lastname: '',
                    email: '',
                    phone: '',
                    company: '',
                    msg: '',
                    options: {}
                }
            },

            error: {
                name: false,
                email: false,
                service: false
            },

            regexEmail: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
        }
    },

    components: {
        Footer,
        ServiceQuickSelector,
        VueRecaptcha,
        Modal
    },

    methods: {




        getQuote: function(service) {
            // Scroll to quotes
            var getQuote = document.getElementById('GetQuote');
            window.scrollTo(0, getQuote.offsetTop - 150);


            this.quote = {
                service: '',
                contact: {
                    name: this.quote.contact.name,
                    lastname: this.quote.contact.lastname,
                    email: this.quote.contact.email,
                    phone: this.quote.contact.phone,
                    company: this.quote.contact.company,
                    msg: this.quote.contact.msg,
                    options: {}
                }
            }


            this.quote.service = service;
        },

        scrollToElement: function(id) {
            var element = document.getElementById(id);
            window.scrollTo(0,element.offsetTop - 100);
            
        },





        canSubmitQuote: function() {

            var flag = true;


            if(!this.regexEmail.test(this.quote.contact.email)) {
                this.error.email = true;
                this.quote.contact.email = 'Please enter a valid email address';
                flag = false;
            }

            if(this.quote.contact.name === '') {
                this.error.name = true;
                flag = false;
            }

            if(this.quote.service === '') {
                this.error.service = true;

                flag = false;
            }

            return flag;

        },




        submitQuote: function() {


            if(!this.canSubmitQuote()) {

                this.modal = {
                    show: true,
                    title: '',
                    body: '',
                    error: true
                }

                if(this.quote.service === '') {

                    this.modal.title = 'Please select a service first.';
                    window.scrollTo(0, document.getElementById('HD').offsetTop - 150 );

                } else {

                    this.modal.title = 'Please fill in all required fields.';
                    window.scrollTo(0, document.getElementById('GetQuote').offsetTop + 100);

                }


                


                return

            }
            
            
            axios.post('quote.php', this.quote, {
                 headers: {'Content-type' : 'application/x-www-form-urlencoded'}
            })

                .then(resp => {

                    if(resp.data.status === 'success') {
                        this.modal = {
                            show: true,
                            title: 'Your message has been sent!',
                            body: 'Thank you for your enquiry, we will get back to you very soon.',
                            error: false
                        }

                        this.quote = {
                            service: '',
                            contact: {
                                name: '',
                                lastname: '',
                                email: '',
                                phone: '',
                                company: '',
                                msg: '',
                                options: {}
                            }
                        }

                    } 
                    
                    else {
                        this.modal = {
                            show: true,
                            title: 'Oh no, your message could not be sent',
                            body: 'Please email us <a href="mailto:info@bravado.co.za">here</a>',
                            error: true
                        }
                    }
                })

        }
           

    }

}
</script>



<style>

.services-wrap {
    width: 100%;
}

@keyframes bounce-arrow {
    0% {
        transform: translateY(-40px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.hero .material-icons.scroll-down {
    font-size: 50px;
    color: rgb(220,0,0);
    margin-top: 1em;
    animation: bounce-arrow 2000ms ease;
    animation-iteration-count: 3;
    cursor: pointer;
}

.hero.services-image {
    background-image: url('../assets/services_background.jpg');
    margin-bottom: 5em;
}

.services-flexbox {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.services {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 5em;
    column-gap: 3em;
    justify-items: center;
    text-align: center;
    padding: 0 1.5em;
    margin-bottom: 2em;
    width: 60%;
}

.service {
    border: 1px solid black;
    border-radius: 5px;
    padding: 2em 1em;
    box-shadow: -1px 3px 12px 0 rgba(0,0,0,0.2);
}

.service-title {
    margin-bottom: 1em;
}

.service-icon {
    max-width: 120px;
}

.service-price {
    margin-bottom: 0.5em;
}

.service-text {
    margin-bottom: 1em;
}



/* Quotes */

#GetQuote {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 3em;
    margin-top: 6em;
}


.quote-heading1 {
      
}

.quote-heading2 {
    margin-bottom: 0.5em;
}

.quote-heading-hr {
    border-color: rgb(220,0,0);
    width: 45vw;
    margin-bottom: 3em;
}

.selected-service {
    text-align: center;
    margin-bottom: 1.5em;
    color: rgb(220,0,0);
    background: white;
    border-radius: 5px;
    padding: 0.5em 2em;
    
}

.service-options  {
    margin-bottom: 3em;
    text-align: center;
    padding: 0 2em;
}

.service-option-selectors {
    margin-bottom: 2em;
}

.text-box {
    margin-top: 1em;
    width: 40vw;
    height: 80px;
}

.meeting-required {
    margin-bottom: 2em;
}


.input-heading {
    text-align: center;
}



.quote-btn {
    margin-top: 2em;
}







/* Mobile Settings */

@media (max-width: 700px) {
    .services {
        grid-template-columns: 1fr;
        width: 100%;
    }

    .text-box {
        width: 70vw;
    }
}

</style>